.c-app > .c-sidebar {
    width: 500px !important;
    margin-left: 0 !important;
    -ms-flex: 0 0 500px !important;
    flex: 0 0 500px !important;
    width: 500px;
    background-color: #0F3D42;
}

.c-app > .c-wrapper {
    margin-left: 500px !important;
    min-width: 600px !important;
}

.c-sidebar-brand {
    justify-content: flex-start;
    padding-left: 20px;
}

.c-sidebar-brand h2 {
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-left: 0;
}

.c-sidebar form h3 {
    margin-bottom: 38px;
}

.c-sidebar form {
    padding: 0 20px 20px;
}

.c-sidebar input {
    background-color: #05474A;
    border-color: #808080;
    color: #b8e6e8;
    font-size: 15px;
}

.c-sidebar input:focus {
    color: #b8e6e8;
}

.c-sidebar input::placeholder {
    color: #727272;
}

.c-sidebar input:focus {
    border-color: #727573;
    background-color: #2e3527;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(120 117 142 / 25%);
}

.c-sidebar form label {
    margin-bottom: 3px;
    color: #e2e2e2;
    display: block;
}

.c-sidebar form label svg {
    width: 15px;
    height: 15px;
    margin-left: 10px;
    margin-top: -3px;
}

.c-sidebar .c-body {
    overflow-y: auto;
}

.compose-dialog {
    width: 100%;
    max-width: 800px;
    min-height: 600px;
    margin: 0 auto 30px;
    background-color: white;
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    -webkit-box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%)
}

.compose-dialog a {
    text-decoration: none;
}

.message {
    padding: 34px 20px 20px;
}

.top-bar {
    background-color: #404040;
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    margin: 0;
    padding: 0;
}

.content {
    -webkit-border-radius: 8px 8px 0 0;
    border-radius: 8px 8px 0 0;
    margin-left: 16px;
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
    height: auto;
    line-height: 20px;
    color: white;
}

.error {
    color: red;
    font-weight: bold;
    display: inline-block;
    vertical-align: bottom;
    margin-bottom: 5px;
    margin-left: 30px;
}

.signature {
    margin-left: 0;
    margin-top: 15px;
}

#fullname span {
    font-size: 1rem;
    font-weight: bold;
}

#fullname span, .icon {
    color: #009991;
}

.icon {
    font-family: monospace, monospace !important;
    font-weight: bold;
}

.quote {
    font-style: italic;
    margin-top: 2px;
}

.modal .signature {
    width: 400px;
    margin: 40px auto;
}

.modal h4 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 8px;
    margin-top: 40px;
    margin-bottom: 10px;
}

.modal h5 {
    margin-bottom: 3px;
    margin-top: 22px;
}

.thankyou {
    margin: 30px auto 50px;
}

.tutorial-image {
    max-width: 100%;
}

.modal ul {
    margin-top: 20px;
}

.modal ul li {
    margin-top: 10px;
}

.signature table {
    border:none;border-collapse:collapse;width:100%;max-width:369px;
}

.sig-border-top {
    border-top: 1px solid rgb(217, 217, 217);
    vertical-align: top;
    padding: 10px 5px 10px 0;
    overflow: hidden;
    overflow-wrap: break-word;
}

.sig-border-top.leftpadding {
    padding-left: 5px;
}

.cellInner {
    line-height:1.38;
    margin-top:0;
    margin-bottom:0;
}

.cellInner.large {
    line-height:1.2;
    margin-bottom: 3px;
}

.cellInner.large span {
    font-size: 1.2rem;
    font-weight: bold;
}

.cellInner span {
    font-size: 0.9rem;
    font-family: Calibri, sans-serif;
    color: #19686B;
    background-color: transparent;
    font-variant-numeric: normal;
    font-variant-east-asian: normal;
    vertical-align: baseline;
    white-space: pre-wrap;
}

.signature-footer {
    vertical-align:middle;background-color:#05474a;padding:0 0.25rem;overflow:hidden;overflow-wrap:break-word;
}

.signature-footer > table {
    width: 98%
}

.social-links {
    text-align: right;
}

.signature-footer p {
    line-height:1.2;margin-top:0px;margin-bottom:0px;
}

.care-access {
    font-size: 0.9rem; font-family: Palatino, serif; color: rgb(171, 201, 186); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: middle; white-space: pre-wrap;
}

.emptySpace1 {
    font-size: 1.6rem; font-family: Palatino, serif; color: rgb(171, 201, 186); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: middle; white-space: pre-wrap;
}

.emptySpace2 {
    font-size: 0.9rem; font-family: Palatino, serif; color: rgb(171, 201, 186); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;
}

.social-link {
    font-size: 0.6rem; font-family: Verdana; color: rgb(171, 201, 186); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;
}

.separator {
    font-size: 0.6rem; font-family: Verdana; color: rgb(171, 201, 186); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; vertical-align: baseline; white-space: pre-wrap;
}

.height-0 {
    height: 0;
}

.height-0 td {
    vertical-align: top; overflow: hidden; overflow-wrap: break-word;
}

.logo-wrapper {
    font-size: 2.25rem; font-family: Arial; color: rgb(17, 85, 204); background-color: transparent; font-variant-numeric: normal; font-variant-east-asian: normal; text-decoration: underline; text-decoration-skip-ink: none; vertical-align: baseline; white-space: pre-wrap;
}

.logo-wrapper span {
    border:none;display:inline-block;overflow:hidden;width:364px;height:48px;
}

.logo-wrapper img, .sig-border-top img {
    margin-left:0px;margin-top:0px;
}

.cellInner .signature-link, .care-mark {
    color: #19686B;
    cursor: pointer;
}

.btn-signature {
    background-color: #009991;
    border-color: #009991;
    margin-top: 40px;
    text-transform: uppercase;
    padding: 14px 20px
}

.btn-signature:hover, .btn-signature:focus {
    background-color: #00716c;
    border-color: #00716c;
}

.btn-signature span {
    margin-left: 20px;
    vertical-align: top;
    margin-top: 5px;
}

.c-avatar {
    padding: 4px;
    display: inline-block;
    border-radius: 2px;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    position: relative;
    margin-right: 30px;
}

.c-avatar img {
    -webkit-background-size: 50px 50px;
    background-size: 44px 44px;
    border: 0;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: block;
    margin: 0px;
    position: relative;
    height: 44px;
    width: 44px;
    z-index: 0;
}

.userProfile a {
    color: rgb(0, 153, 145);
    display: inline-block;
    margin-right: 12px;
    margin-top: 20px;
    vertical-align: top;
}

.userProfile a:hover, .userProfile a:focus {
    color: rgb(5, 71, 74);
}

.sign-in.card-body {
    height: 40px;
}

.modal-backdrop.show {
    opacity: 0.95;
    background-color: rgb(5, 71, 74);
}

.modal .c-avatar {
    display: block;
    width: 168px;
    height: 168px;
    margin: 20px auto;
}

.modal .c-avatar img {
    background-size: 160px 160px;
    height: 160px;
    width: 160px;
}

.form-group .c-switch {
    margin-top: 22px;
    margin-right: 14px;
}

.form-group .c-switch + label{
    margin-bottom: 22px;
    vertical-align: middle;
}

.see-instructions .btn {
    margin-top: 14px !important;
    margin-left: 30px !important;
    color: rgb(0, 153, 145) !important;
    border-color: rgb(0, 153, 145) !important;
}

.see-instructions .btn:hover, .see-instructions .btn:focus, .see-instructions .btn:active,
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active {
    color:white !important;
    background-color: rgb(0, 153, 145) !important;
    border-color: rgb(0, 153, 145) !important;
}

#collapseHeadshotGuidelines h3 {
    font-size: 1.2rem;
    margin: 0.5rem 0 1rem 0;
}

.modal #collapseHeadshotGuidelines h5 {
    margin-top: 10px;
    font-size: 1rem;
}

.modal #collapseHeadshotGuidelines ul, .modal #collapseHeadshotGuidelines li {
    margin-top: 5px;
}

.cellInner .icon {
    background:url(https://careaccess.com/email-sig-icons.png) 0 0 no-repeat;
    display: inline-block;
    height: 16px;
    width: 26px;
    vertical-align: middle;
}

.icon.phoneIcon {
    background-position-y: -16px;
}

.icon.printerIcon {
    background-position-y: 0;
}

.icon.emailIcon {
    background-position-y: -32px;
}

.icon.locationIcon {
    background-position-y: -48px;
}

.icon.laptopIcon {
    background-position-y: -64px;
}

.icon.calendarIcon {
    background-position-y: -80px;
}